import { PointsModel } from '@/common/models/points.model'
import { SpeciesModel } from '@/common/models/species.model'
import { StatesModel } from '@/common/models/states.model'
import { AppEvent } from '@/common/types/events.types'
import { client } from '@/graphql'
import { MUTATE_POINTS } from '@/graphql/mutations/mutatePoints'
import pubsub from '@/pubsub'
import { provideApolloClient, useMutation } from '@vue/apollo-composable'
import { useDebounceFn } from '@vueuse/core'
import groupBy from 'lodash/groupBy'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useProfileStore = defineStore('profile', () => {
  const { mutate, onDone, onError } = provideApolloClient(client)(() =>
    useMutation(MUTATE_POINTS),
  )

  const residency = ref<StatesModel[]>([])
  const points = ref<PointsModel[]>([])

  const residencyQuery = computed(() => {
    if (residency.value.length > 0) {
      return residency.value.map((item) => {
        return item.id
      })
    }
    return null
  })

  const pointsQuery = computed(() => {
    if (points.value.length > 0) {
      return points.value.map((item) => {
        return {
          points: item.points,
          speciesID: item.species.id,
          stateID: item.state.id,
          type: item.type,
        }
      })
    }
    return null
  })

  const pointsByState = computed(() => {
    return groupBy(points.value, 'state.name')
  })

  function updateResidency(value: StatesModel[]) {
    residency.value = value
  }

  function updatePoints(value: PointsModel[]) {
    points.value = value
  }

  function _emitEditEvent(event: string, point: PointsModel) {
    pubsub.publish(AppEvent.MyPointsCardEdited, {
      state: point.state.name,
      species: point.species.name,
      points_type: _getPointTypeTitle(point.type),
      edit_type: event,
      total_card_points: point.points,
    })
  }

  function _mutatePoint(newPoint: PointsModel, oldPoint: PointsModel) {
    return new Promise<void>((resolve, reject) => {
      mutate({
        input: {
          points: newPoint.points,
          speciesID: newPoint.species?.id,
          stateID: newPoint.state?.id,
          type: newPoint.type,
        },
      })
      if (oldPoint.points !== newPoint.points) {
        _emitEditEvent(
          oldPoint.points < newPoint.points ? 'incremented' : 'decremented',
          newPoint,
        )
      }
      onDone(() => {
        return resolve()
      })
      onError(() => {
        return reject()
      })
    })
  }

  const _debounceMutatePoint = useDebounceFn(
    async (newPoint: PointsModel, oldPoint: PointsModel) => {
      await _mutatePoint(newPoint, oldPoint)
    },
    1000,
  )

  function _getPointTypeTitle(pointType: SpeciesModel['pointType']) {
    if (!pointType) return ''

    switch (pointType) {
      case 'PREFERENCE':
        return 'Preference'
      case 'BONUS':
        return 'Bonus'
      default:
        return pointType
    }
  }

  async function incrementPoint(point: PointsModel) {
    const updatedPoint = {
      ...point,
      points: Number(point.points) + 1,
    }
    const updatePointsArray = points.value.map((item) => {
      return item.id === point.id ? updatedPoint : item
    })
    points.value = updatePointsArray
    await _debounceMutatePoint(updatedPoint, point)
  }

  async function decrementPoint(point: PointsModel) {
    const updatedPoint = {
      ...point,
      points: Number(point.points) - 1,
    }
    const updatePointsArray = points.value.map((item) => {
      return item.id === point.id ? updatedPoint : item
    })
    points.value = updatePointsArray
    await _debounceMutatePoint(updatedPoint, point)
  }

  async function updatePoint(point: PointsModel, newPoint: number) {
    const updatedPoint = {
      ...point,
      points: Number(newPoint),
    }
    const updatePointsArray = points.value.map((item) => {
      return item.id === point.id ? updatedPoint : item
    })
    points.value = updatePointsArray
    await _mutatePoint(updatedPoint, point)
  }

  async function deletePoint(point: PointsModel) {
    const updatedPoint = {
      ...point,
      points: 0,
    }
    await _mutatePoint(updatedPoint, point)
    const filteredPoints = points.value.filter((item) => item.id !== point.id)
    points.value = filteredPoints
    pubsub.publish(AppEvent.MyPointsCardDeleted, {
      state: point.state.name,
      species: point.species.name,
      points_type: _getPointTypeTitle(point.type),
      total_card_points: point.points,
    })
  }

  return {
    residency,
    points,
    residencyQuery,
    pointsQuery,
    pointsByState,
    updateResidency,
    updatePoints,
    incrementPoint,
    decrementPoint,
    deletePoint,
    updatePoint,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
